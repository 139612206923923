/* eslint-disable camelcase */
/* eslint-disable max-lines */
import { handleElementVisibility, keepAppointmentSlotReservationAlive, useStorage } from './helpers.js';

const { $, moment, gtag, google, book: { bubbleMessages, route, packageId, inspectionTypes } } = window;
const availabilityCheckInterval = 5000;
const daysAllowedToBookUpfront = 15;
let currBubbleMessage = 1;
let firstAvailableDate = '';
let zipError = false;
let datesCarousel;
let selectedPackage = null;
let autoComplete = null;
let place = null;
let address = '';
let autoSubmitAddress = false;

const errorFields = [
    '.address-error',
    '.service-error',
    '.zipcode-error',
    '.package-error',
];

if (packageId) {
    useStorage().set('package', packageId);
    useStorage().set('price', inspectionTypes[packageId].price);
}

if (useStorage().get('checkout')) {
    useStorage().clear();
}

if (useStorage().get('place')) {
    place = useStorage().get('place');
}

if (useStorage().get('owner[address]')) {
    address = useStorage().get('owner[address]');
}

if (useStorage().get('package')) {
    selectedPackage = useStorage().get('package');

    useStorage().set('price', inspectionTypes[selectedPackage].price);
}

setInterval(checkAvailability, availabilityCheckInterval);

function findPosition(from, to) {
    const stepPos1 = $(from).offset();
    const stepPos2 = $(to).offset();

    return stepPos2.top - stepPos1.top;
}

function changeBubbleText() {
    $('.js-bubble').html(bubbleMessages[currBubbleMessage]);

    currBubbleMessage++;
}

function convertSlotsToDateCarousel(slots) {
    return $.map(slots, function(slot, key) {
        return {
            date: key,
            datePretty: moment(key).format('ddd D MMM'),
            hours: $.map(slot, function(providers, time) {
                const split = time.split(':');

                return {
                    hour: split[0] + ':' + split[1],
                    provider: providers[0] || null,
                    slot: time,
                    status: providers.length > 0,
                };
            }),
        };
    });
}

function setFirstAvailableDate(slots) {
    if (firstAvailableDate == '' && !zipError) {
        slots.forEach(function(row) {
            row.hours.forEach(function(entry) {
                if (entry.status && firstAvailableDate == '') {
                    firstAvailableDate = row.date;

                    const a = moment(row.date);
                    const b = moment();
                    const daysDiff = a.diff(b, 'days') + 1;

                    gtag('event', $('#zipcode').val(), {
                        event_category: 'Availability Search',
                        event_label: daysDiff,
                        value: daysDiff,
                    });
                }
            });
        });
    }
}

function createDateCarousel(obj) {
    const currentSlot = useStorage().get('slot');
    const currentDate = useStorage().get('date');
    const currentProvider = useStorage().get('provider');

    // Cleanup
    datesCarousel.removeAllSlides();

    for (let j = 0; j < obj.length; j++) {
        let hours = '';
        const date = obj[j].datePretty.substring(0, 3) + ', ' + obj[j].datePretty.substring(3);

        obj[j].hours.forEach(function(entry) {
            const dateID = String(obj[j].date + entry.hour).replace('-', '').replace(':', '');

            if (currentSlot == entry.slot && currentDate == obj[j].date) {
                hours +=
                    `
                        <li>
                            <a 
                                id="${dateID}" 
                                class="js-hour active" 
                                data-date-pretty="${date}" 
                                data-provider="${currentProvider}"
                                data-slot="${currentSlot}"
                                data-date="${currentDate}"
                                data-hour="${entry.hour}"
                            >
                                ${entry.hour}
                            </a>
                        </li>
                    `;
            } else {
                if (date && entry && entry.status) {
                    hours +=
                        `
                            <li>
                                <a 
                                    id="${dateID}" 
                                    class="js-hour" 
                                    data-date-pretty="${date}" 
                                    data-provider="${entry.provider}"
                                    data-slot="${entry.slot}"
                                    data-date="${obj[j].date}"
                                    data-hour="${entry.hour}"
                                >
                                    ${entry.hour}
                                </a>
                            </li>
                        `;
                } else {
                    hours += `<li><a id="${dateID}" class="js-hour inactive">-</a></li>`;
                }
            }
        });

        const noAvailabilityHtml = `
            <li><a class="js-hour inactive">-</a></li>
            <li><a class="js-hour inactive">-</a></li>
            <li><a class="js-hour inactive">-</a></li>
            <li><a class="js-hour inactive">-</a></li>
        `;

        const slide =
            `
                <div class="swiper-slide">
                    <div class="date-col">
                        <h6 class="js-date">
                            ${date.split(',').join('<br/>')}
                        </h6>
                        <ul>
                            ${hours != '' ? hours : noAvailabilityHtml}
                        </ul>
                    </div>
                </div>                    
            `;

        datesCarousel.appendSlide(slide);
    }

    if (!currentSlot) {
        $('#date-next-btn').disable(true);
    }

    $('.substep12').removeClass('loading');

    datesCarousel.update(true);
}

function updateDateCarousel(obj) {
    const currentSlot = useStorage().get('slot');
    const currentDate = useStorage().get('date');

    obj.forEach(function(row) {
        row.hours.forEach(function(entry) {
            const dateID = String(row.date + entry.hour).replace('-', '').replace(':', '');

            if (currentSlot == entry.slot && currentDate == row.date) {
                $('#' + dateID).removeClass('inactive').addClass('active');

                return;
            }

            entry.status
                ? $('#' + dateID).removeClass('inactive').text(entry.hour)
                : $('#' + dateID).not('.active').addClass('inactive').text('-');
        });
    });

    $('.js-hour').removeClass('locked');
}

function animateTransitionToSlots() {
    if ($('.substep12').hasClass('loading')) {
        $('.substep12').removeClass('loading');
    }

    // Show next substep
    $('.substep12').addClass('loading').fadeIn();

    // Move Bubble and change its message
    $('html, body').animate({
        scrollTop: $('.substep12').offset().top,
    }, 1000);

    if (!$('.mobile-header').is(':visible')) {
        $('#sidebar').css('top', findPosition(
            '.substep11',
            '.dates-carousel-cont',
        ));
    }
}

function checkAvailability(datesCreated = true) {
    if (useStorage().get('owner[address]') && useStorage().get('zipcode') && useStorage().get('locationId')) {
        const params = {
            dend: moment().add(daysAllowedToBookUpfront, 'day').format('YYYY-MM-DD'),
            dstart: moment().add(1, 'day').format('YYYY-MM-DD'),
            location_id: parseInt(useStorage().get('locationId'), 10),
            service_id: inspectionTypes[selectedPackage ?? useStorage().get('package')].related_service,
        };

        $.ajax({
            url: route.api.v3.appointment.availability + '/?' + $.param(params),
        })
            .done(function(res) {
                if (res && res.success && res.success === 'ok') {
                    const serviceAvailable = Boolean(Object
                        .keys(res.data.slots)
                        .filter(item => Object.keys(res.data.slots[item]).length)
                        .length,
                    );

                    if (!serviceAvailable) {
                        handleElementVisibility('.service-error', 'show');

                        $('.substep12').attr('style', 'display: none');

                        return;
                    } else {
                        handleElementVisibility('.service-error', 'hide');
                    }

                    if (!datesCreated) {
                        animateTransitionToSlots();
                    }

                    const convertedSlots = convertSlotsToDateCarousel(res.data.slots);

                    useStorage().set('service', res.data.service);

                    setFirstAvailableDate(convertedSlots);

                    datesCreated
                        ? updateDateCarousel(convertedSlots)
                        : createDateCarousel(convertedSlots);

                    keepAppointmentSlotReservationAlive();
                } else {
                    console.error(
                        'FAILED TO LOAD APPOINTMENT/SLOT AVAILABILITY BECAUSE OF BUG',
                    );
                }
            })
            .fail(function() {
                console.error(
                    'FAILED TO LOAD APPOINTMENT/SLOT AVAILABILITY BECAUSE OF CONNECTION',
                );
            });
    }
}

function checkPackageLocation() {
    const id = selectedPackage ?? useStorage().get('package');

    if (!id) {
        return;
    }

    if (inspectionTypes[id]?.location) {
        address = inspectionTypes[id]?.location.address;

        useStorage().set('owner[address]', address);
        useStorage().set('zipcode', inspectionTypes[id]?.location.zip);

        $('#service-location-address').text(address);
        $('#service-location-map-url').attr('href', inspectionTypes[id]?.location.map_url);

        handleElementVisibility('#address-input-row', 'hide');
        handleElementVisibility('#service-location-row', 'show');

        $('input[name="owner[address]"]').val('');

        findByZip(inspectionTypes[id]?.location.zip);
    } else {
        handleElementVisibility('#service-location-row', 'hide');
        handleElementVisibility('#address-input-row', 'show');
    }
}

function renderAutocomplete() {
    const input = $('input[name="owner[address]"]')[0];

    autoComplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: 'GR' },
        fields: ['address_components', 'geometry'],
        language: 'el',
        strictBounds: true,
        types: ['address'],
    });

    autoComplete.addListener('place_changed', function() {
        Object.keys(sessionStorage)
            .filter(key => !['session', 'price', 'package'].includes(key))
            .forEach(key => useStorage().get(key) && useStorage().remove(key));

        errorFields.forEach(function(field) {
            handleElementVisibility(field, 'hide');
        });

        place = autoComplete.getPlace();

        address = '';

        place.address_components.forEach((item, index) =>
            address += item?.long_name + (index < (place.address_components.length - 1) ? ', ' : '.'),
        );

        useStorage().set('place', place);
        useStorage().set('owner[address]', address);
        useStorage().remove('zipcode');

        if (autoSubmitAddress) {
            $('#zip-submit').click();
        }

        autoSubmitAddress = true;
    });
}

function resetServiceSelection() {
    address = '';
    place = null;
    selectedPackage = null;
    currBubbleMessage = 0;
    autoSubmitAddress = false;

    changeBubbleText();

    if (!$('.mobile-header').is(':visible')) {
        $('#sidebar').css('top', 0);
    }

    const sessionDataToRemove = [
        'locationId',
        'owner[address]',
        'package',
        'place',
        'price',
        'service',
        'zipcode',
    ];

    Object.keys(sessionStorage)
        .filter(key => sessionDataToRemove.includes(key))
        .forEach(key => useStorage().get(key) && useStorage().remove(key));
}

function findByZip(zipcode) {
    $.ajax({
        url: route.api.v3.location.findbyzip + '?code=' + zipcode,
    })
        .done(function(res) {
            if (res && res.data && res.data.location) {
                useStorage().set('locationId', res.data.location.id);

                handleElementVisibility('.zipcode-error', 'hide');

                $('.js-date-label').text($('.js-date-label').attr('data-labelAlt'));

                changeBubbleText();

                firstAvailableDate = '';

                zipError = false;

                checkAvailability(false);
            } else {
                handleElementVisibility('.zipcode-error', 'show');

                zipError = true;

                gtag('event', $('#zipcode').val(), {
                    event_category: 'Availability Search',
                    event_label: 'notavailable',
                });
            }
        })
        .fail(function() {
            handleElementVisibility('.zipcode-error', 'show');

            zipError = true;

            gtag('event', $('#zipcode').val(), {
                event_category: 'Availability Search',
                event_label: 'notavailable',
            });
        });
}

$(document).on('click', '.js-hour:not(.inactive):not(.active):not(.locked)', function(e) {
    e.preventDefault();

    const data = $(this).data();

    if (data && data.slot && data.date && data.provider) {
        $('.js-hour').removeClass('active').addClass('locked');

        $(this).addClass('active');

        useStorage().set('slot', data.slot);
        useStorage().set('date', data.date);
        useStorage().set('provider', data.provider);
        useStorage().set('datetime', data.datePretty + ' ' + data.hour);

        $('#date-next-btn').disable(false);
    }

    checkAvailability();
});

$(document).ready(function() {
    datesCarousel = $('.js-dates-carousel').swiper({
        autoplay: false,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            420: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            480: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            540: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            660: {
                slidesPerView: 5,
                spaceBetween: 10,
            },
            800: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
            960: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
        direction: 'horizontal',
        freeMode: false,
        lazyLoading: false,
        loop: false,
        nextButton: '.dates-carousel-cont .swiper-button-next',
        preloadImages: false,
        prevButton: '.dates-carousel-cont .swiper-button-prev',
        preventClicks: false,
        preventClicksPropagation: false,
        simulateTouch: false,
        slidesPerView: 5,
        spaceBetween: 10,
    });

    renderAutocomplete();

    checkPackageLocation();

    $('.js-details-link').click(function(e) {
        e.preventDefault();

        $(this).addClass('hidden');

        if ($(this).next('.radiogroup__details').hasClass('active')) {
            $(this).next('.radiogroup__details').removeClass('active');
        } else {
            $(this).next('.radiogroup__details').addClass('active');
        }
    });

    $('[name="package"]').change(function() {
        $('.substep12').attr('style', 'display: none');

        resetServiceSelection();

        errorFields.forEach(function(field) {
            handleElementVisibility(field, 'hide');
        });

        firstAvailableDate = '';
        selectedPackage = $(this).val();

        useStorage().set('price', $(this).data('price'));

        checkPackageLocation();

        checkAvailability(false);
    });

    $('#appointmentForm').validate({
        ignore: '',
        submitHandler: function() {
            $('#zip-submit').addClass('running');

            let zipcode = useStorage().get('zipcode') ?? null;

            if (!zipcode && place) {
                const zipcodeData = place.address_components
                    .filter(item => item?.types.includes('postal_code'))
                    .map(item => item?.long_name);

                $('.js-address').each(function() {
                    $(this).text(address);
                });

                if (!zipcodeData.length) {
                    useStorage().get('zipcode') && useStorage().remove('zipcode');

                    handleElementVisibility('.address-error', 'show');

                    return;
                }

                zipcode = zipcodeData[0].replace(' ', '');

                useStorage().set('zipcode', zipcode);
            } else if (!zipcode && !place) {
                handleElementVisibility('.address-error', 'show');

                return;
            }

            handleElementVisibility('.address-error', 'hide');

            findByZip(zipcode);

            $('#zip-submit').removeClass('running');
        },
    });

    $(window).load(function() {
        if (useStorage().get('slot')) {
            useStorage().get('slot') && $('#date-next-btn').disable(false);
        }
    });
});